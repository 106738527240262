@font-face {
  font-family: Devator;
  src: url(./assets/fonts/Devator.ttf);
}

@font-face {
  font-family: Monda;
  src: url(./assets/fonts/Monda-Regular.ttf);
}

@font-face {
  font-family: Monda-Bold;
  src: url(./assets/fonts/Monda-Bold.ttf);
}

@font-face {
  font-family: Panchang;
  src: url(./assets/fonts/Panchang-Medium.woff);
}

@font-face {
  font-family: PanchangSemi;
  src: url(./assets/fonts/Panchang-Semibold.woff);
}

@font-face {
  font-family: PanchangBold;
  src: url(./assets/fonts/Panchang-Bold.woff);
}

@font-face {
  font-family: Chillax;
  src: url(./assets/fonts/Chillax-Medium.woff);
}

@font-face {
  font-family: ChillaxSemi;
  src: url(./assets/fonts/Chillax-Semibold.woff);
}

@font-face {
  font-family: ChillaxBold;
  src: url(./assets/fonts/Chillax-Bold.woff);
}
@font-face {
  font-family: OpenSansMedium;
  src: url(./assets/fonts/OpenSans-Medium.ttf);
}
@font-face {
  font-family: OpenSansBold;
  src: url(./assets/fonts/OpenSans-Bold.ttf);
}
@font-face {
  font-family: OpenSansSemiBold;
  src: url(./assets/fonts/OpenSans-SemiBold.ttf);
}
@font-face {
  font-family: OpenSansRegular;
  src: url(./assets/fonts/OpenSans-Regular.ttf);
}

/* new font */
@font-face {
  font-family: ClashDisplayRegular;
  src: url(./assets//fontv2/ClashDisplay/ClashDisplay-Regular.ttf);
}
@font-face {
  font-family: ClashDisplayMedium;
  src: url(./assets//fontv2/ClashDisplay/ClashDisplay-Medium.ttf);
}

@font-face {
  font-family: ClashDisplay;
  src: url(./assets//fontv2/ClashDisplay/ClashDisplay-Medium.ttf);
}

@font-face {
  font-family: ClashDisplaySemiBold;
  src: url(./assets//fontv2/ClashDisplay/ClashDisplay-Bold.ttf);
}
@font-face {
  font-family: ClashDisplayBold;
  src: url(./assets//fontv2/ClashDisplay/ClashDisplay-Medium.ttf);
}

@font-face {
  font-family: SPHEREFEZ;
  src: url(./assets//fontv2/SphereFez-8MAzJ.ttf);
}

.blur::before {
  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
  width: contain;
  border-radius: 4px;
  padding: 0.3rem;
}
.blur {
  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
  width: contain;
  border-radius: 4px;
  padding: 0.3rem;
}

.title-red {
  background: linear-gradient(90.02deg, #c80e0e 18.25%, #ae1717 82.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}

.space {
  white-space: nowrap;
}

/* :focus-visible {
  display: none !important;
  outline: -webkit-focus-ring-color auto 1px;
}  */

.btn-bsc-icon-loading {
  -webkit-animation: btnSpin 2s infinite linear;
  -moz-animation: btnSpin 2s infinite linear;
  -o-animation: btnSpin 2s infinite linear;
  -ms-animation: btnSpin 2s infinite linear;
  animation: btnSpin 2s infinite linear;
}
@-webkit-keyframes btnSpin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes btnSpin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes btnSpin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@-ms-keyframes btnSpin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}
@keyframes btnSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.overFlow {
  overflow: hidden;
}

.cus-pt-global {
  padding-top: 68px;
}

/* @media screen and (max-width: 1126px) {
  .cus-pt-global {
    padding-top: 180px;
  }
} */

@media screen and (max-width: 840px) {
  .cus-pt-global {
    padding-top: 68px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
    background: transparent;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
